.Profile {
  margin: auto;
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 50px;
  background-image: url('./images/Pattern.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color:#F5F5F5;
  height: 100%;
}

.ProfileContainer {
    border: 1px solid #F5F5F5;
    border-radius: 24px;
    background-color: white;
    padding: 30px;
    margin-bottom: 20px;
}

.ProfileButtons {
    padding-top: 50px;
}

.SignOut {
    text-align: right;
}

.Loading {
    margin: auto;
    padding-top: 30px;
    width: 300px;
}

.ProfileButton {
    width: 30%;
    min-width: 200px;
    max-width: 300px;
}

.Spacer {
    padding-top: 20px;
}