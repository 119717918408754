.ContactUs {
    display: flex;
    border-radius: 100px;
    height: 50px;
    align-items : center;
    color: white;
    background-color: rgba(224, 224,224, 0.2);
    font-weight: 600;
}

.ContactUsDark {
    display: flex;
    border-radius: 100px;
    height: 50px;
    align-items : center;
    color: #8F8F8F;
    background-color: #F5F5F5;
    font-weight: 600;
}

.ContactUs span, .ContactUsDark span {
    padding-left: 15px;
    padding-right: 15px;
}