.BookRide {
    padding: 20px;
    background-image: url('./images/Pattern.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color:#F5F5F5;
    height: 100%;
}

.LegCloseButton {
    text-align: right;
}

.BookRideSubmitted {
    text-align: center;
    margin: auto;
    padding-top: 100px;
    width: 50%;
}

.TheSmallPrint {
    font-size: 10pt;
}

.ConfirmationCode {
    font-size: 14pt;
    padding-top: 20px;
}

.CostBreakDown {
    border-bottom: thin solid black;
    font-size: 12pt;
    padding-top: 30px;
    min-width: 500px;
    width: 50%;
}

.CostTotal {
    font-size: 12pt;
    padding-bottom: 30px;
    min-width: 500px;
    width: 50%;
}

.CostRow {
    display: flex;
    just-content: space-between;
}

.CostCell {
    display: inline-block;
    width: 250px;
}

.Map {
    height: 400px;
    width: 600px;
}

.ThankYou {
    font-size: 18pt;
    font-weight: bold;
}

.ConfirmContact {
    font-size: 12pt;
    font-weight: bold;
}

.Confirmation {
    padding: 10px;
}

.AreaOfService {
    font-size: 14pt;
    font-weight: bold;
}

.StripePayment {
    min-width: 400px;
    width: 50%;
}

.ServiceCost {
    font-size: 16pt;
    padding-top: 50px;
}

.Tip {
    border-bottom: thin solid black;
    font-size: 14pt;
    padding-bottom: 20px;
    padding-top: 20px;
}

.Total {
    font-size: 16pt;
    padding-top: 20px;
}

.Summary {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.SummaryHeader {
    display: flex;
    font-size: 18px;
    font-weight: 500;
}

.SummaryHeaderSubText {
    display: flex;
    font-size: 18px;
    color: #8F8F8F;

}

.SummaryHeader span {
    display: flex;
    vertical-align: top;
}

.AssistanceHeader {
    display: inline-block;
    padding-right: 30px;
}

.HeaderCompleted {
    font-weight: normal;
}

.ContinueButton {
    display: flex;
    width: 300px;
}


.ServiceLevelDescription {
    display: flex;
    align-items: center;
    border-radius: 16px;
    background-color:  #F1F6F9;
    border: 2px solid #D1E4FF;
    height: 50px;
    jusify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
}

.ServiceLevelDescription span {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
}

@media (max-width: 800px) {
    .ServiceLevelDescription {
        height:80px;
    }
}

.ServiceLevelStar {
    background-image: url('./images/kid_star-filled.png');
    background-color: #D1E4FF;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    display: inline-block;
    height: 20px;
    width: 20px;
    padding: 5px;
}

.ProcessPayment {
    display: inline-block;
    background-color: white;
    border-radius: 24px;
    width: 200px;
    height: 200px;
    font-size: 18pt;
    text-align: center;
}

.ApplyButton {
    display: inline-block;
    width: 125px;
}

.PromoCodeSection {
    display: inline-block;
    width: 380px;
}

.PromoCode {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 380px;
}

.PromoCodeError {
    color: red;
    font-size: 10pt;
    text-align: center;
}

@media (max-width: 800px) {
    .PromoCodeError {
        text-align: left;
    }
}