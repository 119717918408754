.StripePayment {
    text-align: center;
    width: 300px;
}

@media (min-width: 600px) {
    form {
        min-width: 600px;
    }
}

@media (max-width: 600px) {
    form {
        max-width: 300px;
    }
}

.PaymentButtons {
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.ErrorMessage {
    color: red;
}

.PaymentButton {
    display: flex;
    border-radius: 100px;
    background-color: #F85804;
    height: 50px;
    min-width: 300px;
    align-items : center;
    margin-left: auto;
    margin-right: auto;
    color: white;
    cursor: pointer;
}

.PaymentInnerButton {
    width: 280px;
}

.PaymentButton button, .PaymentButtonDisabled button {
    border: none;
    background: none;
    margin: auto;
    font-size: 16px;
    font-family: poppins;
    font-weight: 600;
    color: white;
}

.PaymentButtonDisabled {
    display: flex;
    border-radius: 100px;
    background-color: #CDCED5;
    height: 50px;
    width: 150px;
    align-items : center;
    width: 60%px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    max-width: 400px;
}

.PaymentButton:hover button {
    color: black;
}