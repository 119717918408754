.LogoContainer {
    background-image: url('./images/TUXRydesMarkBlack.png');
    background-color: white;
    background-size: 40px 33px; 
    background-repeat: no-repeat;
    background-position: center; 
    border-radius: 30px; 
    border: 1px solid #E0E0E0;
    display: inline-block; 
    height: 60px;
    width: 60px;
    padding: 5px; 
    box-sizing: border-box; 
}

.LogoBox {
    align-items: center;
    font-size: 17pt;
    font-weight: 600;
    display: flex;
    width: 180px;
    justify-content: space-between;
}

.LogoLink {
    color: black;
    text-decoration: none;
}
