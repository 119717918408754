.RideDetails {
    padding: 20px;
    background-image: url('../pages/images/Pattern.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color:#F5F5F5;
    height: 100%;
    font-size: 14pt;
}

.DetailsTitle {
    font-size: 20pt;
    font-weight: bold;
}

.ServiceLevel, .Status {
    font-size: 14pt;
    padding: 5px;
}