.InnerCirleClear,  .InnerCirleWhite, .InnerCirleOrange {
    border: 1px solid rgba(224, 224,224, 0.2);
    border-radius: 30px;
    display: inline-block;
    width: 30px;
    height: 30px;
    align-items : center;
}

.InnerCirleClear {
    border: 1px solid rgba(224, 224,224, 0.2);
}

.InnerCirleWhite {
    border: 1px solid #E0E0E0;
}

.InnerCirleOrange {
    border: 1px solid white;
}

.TextContainer {
    display: flex;
    height: 30px;
    justify-content: space-between;
    width: 100%;
}

.InnerText {
    display: flex;
    height: 30px;
    align-items : center;
    margin-right: 5px;
    margin-left: 5px;
}

.Centered {
    margin: auto;
    text-align: center;
}

.InnerCirleWhite svg, .InnerCirleClear svg, .InnerCirleOrange svg {
    vertical-align: middle;
    padding-top: 3px;
}

.white, .clear, .orange {
    display: flex;
    border-radius: 100px;
    height: 50px;
    align-items : center;
}

.white {
    color: black;
    background-color: white;
}

.clear {
    color: white;
    background-color: rgba(224, 224,224, 0.2);
}

.orange {
    color: white;
    background-color:  #F85804;
}

.bordered {
    border: 2px solid #E0E0E0;
}

.LinkWhite, .LinkClear, .LinkOrange {
    border: none;
    background: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    font-family: poppins;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
}

.LinkWhite {
    color: black;
}