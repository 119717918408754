.ResetPassword {
    display: flex;
    background-image: url('./images/Pattern.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color:#F5F5F5;
    width: 100%;
    height: 100vh;
}

.ResetPasswordContainer {
    background-color: white;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 300px;
    height: 120px;
    border: 1px solid #F5F5F5;
    border-radius: 24px;
    padding: 30px;
}

.error {
  color: red;
}