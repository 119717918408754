.Spacer {
    height: 50px;
    width: 100%;
}

.SpacerSmall {
    display: flex;
    height: 10px;
    width: 100%;
}


@media (max-width: 800px) {
    .Spacer {
        height: 10px;
        width: 100%;
    }
}