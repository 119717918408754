.Summary {
    display: inline-block;
    width: 100%;
}

.SummaryHeader {
    align-items:center;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
}

.SummaryHeader span {
    padding-left: 10px;
}

.SummaryHeaderSubText {
    display: inline-block;
    font-size: 14px;
    color: #8F8F8F;
    padding-top: 5px;
    width: 100%;

}