.TUXActionButton, .TUXActionButtonGhost {
    display: flex;
    border-radius: 100px;
    height: 50px;
    width: 150px;
    align-items : center;
    width: 375px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

.TUXActionButton {
    background-color: #F85804;
    color: white;
}

.TUXActionButtonGhost {
    border: thin solid #F85804;
}

.TUXActionButtonFlex, .TUXActionButtonFlexGhost {
    display: flex;
    border-radius: 100px;
    height: 50px;
    width: 150px;
    align-items : center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    cursor: pointer;
}

.TUXActionButtonFlex {
    background-color: #F85804;
}

.TUXActionButtonFlexGhost {
    border: thin solid #F85804;
}

.TUXActionButtonDisabled, .TUXActionButtonDisabledGhost {
    display: flex;
    border-radius: 100px;
    height: 50px;
    width: 150px;
    align-items : center;
    width: 375px;
    margin-left: auto;
    margin-right: auto;
}

.TUXActionButtonDisabled {
    background-color: #CDCED5;
    color: white;
}

.TUXActionButtonDisabledGhost {
    border: thin solid #CDCED5;
}

.TUXActionButtonFlexDisabled,  .TUXActionButtonFlexDisabledGhost {
    display: flex;
    border-radius: 100px;
    height: 50px;
    width: 150px;
    align-items : center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.TUXActionButtonFlexDisabled {
    color: white;
    background-color: #CDCED5;
}

.TUXActionButtonFlexDisabledGhost {
    border: thin solid #CDCED5;
}

.TUXActionButton button, .TUXActionButtonDisabled button, .TUXActionButtonFlex button, .TUXActionButtonFlexDisabled button,
.TUXActionButtonGhost button, .TUXActionButtonDisabledGhost button, .TUXActionButtonFlexGhost button, .TUXActionButtonFlexDisabledGhost button {
    border: none;
    background: none;
    margin: auto;
    font-size: 16px;
    font-family: poppins;
    font-weight: 600;
}

.TUXActionButton button, .TUXActionButtonDisabled button, .TUXActionButtonFlex button, .TUXActionButtonFlexDisabled button {
    color: white;
}

.TUXActionButtonDisabledGhost button, .TUXActionButtonFlexDisabledGhost button {
    color: lightgrey;
}

.TUXActionButton:hover button, .TUXActionButtonFlex:hover button, .TUXActionButtonGhost:hover button, .TUXActionButtonFlexGhost:hover button {
    color: black;
}

