.ConfirmPayment {
    padding-top:50px;
    text-align: center;
}

.ConfirmPaymentLink {
    font-size: 18px;
    color: #F85804;
    font-weight: 600;
    text-decoration: none;
}