.TUXAppBar {
    font-family: poppins;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    height: 75px;
    width: 100%;
    justify-content: space-between;
    background-color: white;
    vertical-align: middle;
}

.TUXLogoBox {
    display: flex;
    width: 250px;
    margin-left: 50px;
    justify-content: space-between;
}

.TUXAppBarUser {
    display: flex;
    width: 800px;
    justify-content: space-evenly;
}

.TUXAppBarLoggedIn {
    display: flex;
    width: 500px;
    justify-content: space-evenly;
}

.ContactUsBottom {
    width: 80%;
    margin-left: 10px;
}

.LayoutAppBarSmallViewPort {
    margin-left: 10px;
}

.LayoutLink {
    color: black;
    text-decoration: none;
}