.Rides {
    display: flex;
    align-items: center;
    background-image: url('./images/Pattern.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color:#F5F5F5;
    height: 100%;

}

.RidesContainer {
    background-color: white;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 90%;
    border: 1px solid #F5F5F5;
    border-radius: 24px;
    padding: 10px;
}

.RidesButton {
    text-align: left;
    padding-bottom: 10px;
}

.Loading {
    margin: auto;
    padding-top: 30px;
    width: 300px;
}
