@media (min-width: 800px) {
    .SignIn {
      display: flex;
      background-image: url('./images/sign_in_family.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 100vh;
      width: 100%;
      min-height: 700px;
    }
}

@media (max-width: 800px) {
    .SignIn {
      height: 100vh;
      max-width: 360px;
      min-height: 700px;
      margin: auto;
    }
}

.SignInDialog {
  display: inline-block;
  width: 450px;
  margin-bottom: 30px;
}

@media (max-width: 800px) {
    .SignInDialog {
      width: 375px;
    }
}

.SignInContainer {
   border-radius: 24px;
   background-color: white;
   width: 425px;
   margin-left: 24px;
   margin-top: 24px;
   text-align: center;
   max-height: 800px;
}

@media (max-width: 800px) {
    .SignInContainer {
       width: 340px;
    }
}

.SignInLogo {
    width: 375px;
    margin: auto;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: start;
    padding-top: 30px;
}

@media (max-width: 800px) {
    .SignInLogo {
        width: 300px;
    }
}

.SignInMessage {
    font-size: 24px;
    font-weight: 500;
    margin: auto;
    width: 375px;
    padding-top: 20px;
    text-align: left;
}

@media (max-width: 800px) {
    .SignInMessage {
        width: 300px;
    }
}

.OrangeText {
    color: #F85804;
}

.SignInTitle {
    font-size: 24px;
    font-weight: 600;
}

.SignInTitleSubText {
    color: #8F8F8F;
    font-size: 12px;
    font-weight: 400;
}

.SignInControls {
    padding-top: 20px;
}

.CloseButton {
  text-align: right;
  width: 100%;
  padding: 0;
}

.SignInButtonContainer {
    padding-top: 10px;
    width: 375px;
    margin: auto;
}

@media (max-width: 800px) {
    .SignInButtonContainer {
        width: 300px;
    }
}

.SignInResetPassword {
    padding-top: 10px;
    margin: auto;
    width: 375px;
    text-align: center;
}

.SignInTextField {
   margin: auto;
   width: 375px;
   padding-top: 10px;
}

@media (max-width: 800px) {
    .SignInResetPassword {
        width: 300px;
    }
}

@media (max-width: 800px) {
    .SignInTextField {
       width: 300px;
    }
}

.SignInOr {
    color: #B8B8B8;
    font-weight: 600;
    text-align: center;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 20px;
}

.SignInLink {
    font-size: 14px;
    color: #F85804;
    font-weight: 600;
    text-decoration: none;
}

.SignInGoogleText {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 200px;
}

.SignInLinks {
    font-size: 14px;
    font-weight: 400;
    padding-top: 10px;
    width: 375px;
    margin: auto;
}

@media (max-width: 800px) {
    .SignInLinks {
        width: 300px;
    }
}
