.Landing {
    margin: auto;
    padding-top: 10px;
    text-align: center;
}

.UsePhoneNumber {
    font-size: 12pt;
}

.ScheduleButton {
    padding-bottom: 10px;
    padding-top: 10px;
}

@media (min-width: 800px) {
    .ServiceAreaSection {
        display: flex;
        justify-content: space-evenly;
        background-image: url('./images/tuxrydes_family.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
        font-family: 'Poppins';
        width: 95%;
        height: 900px;
        border-radius: 24px;
        border: 1px solid #E0E0E0;
        margin: auto;
        position: relative;
        align-items : center;
    }
}

@media (max-width: 800px) {
    .ServiceAreaSection {
        display: flex;
        justify-content: space-evenly;
        background-image: url('./images/tuxrydes_family.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
        font-family: 'Poppins';
        width: 95%;
        height: 1200px;
        border-radius: 24px;
        border: 1px solid #E0E0E0;
        margin: auto;
        position: relative;
        align-items : center;
    }
}

.ServiceAreaSectionOverlay {
    background-color: black;
    opacity: 0.32;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 24px;
    z-index: 2;
}

.ServiceAreaMain {
    display: inline-block;
    width: 100%;
}

.SeviceAreaSectionBlock {
    position: relative;
    display: inline-block;
    height: 200px;
    width: 500px;
    text-align: left;
    z-index: 10;
}

@media (max-width: 800px) {
    .SeviceAreaSectionBlock {
        height: 500px;
        width: 80%;
    }
}

.SeviceAreaSectionTextHeader {
    font-size: 12pt;
    padding-bottom: 32px;
    padding-left: 20px;
}

.SeviceAreaSectionTextMain {
    font-size: 32pt;
    font-weight: 600;
    padding-left: 20px;
}

@media (min-width: 800px) {
    .ServiceAreaSelection {
        float: right;
        display: inline-block;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        border-width: 2px;
        border-color: rgba(224, 224,224, 0.2);
        width: 300px;
    }
}

@media (max-width: 800px) {
    .ServiceAreaSelection {
        display: inline-block;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        border-width: 2px;
        border-color: rgba(224, 224,224, 0.2);
        width: 100%;
    }
}

.ServiceAreaButton {
    background-color: white;
    border-radius: 16px;
}

.ServiceAreaButtonContinue {
    display: flex;
    width: 90%;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.ServiceAreaText {
    padding-bottom: 5px;
    display: inline-block;
    width: 100%;
    text-align: center;
}

.ServiceAreaTextTitle {
    padding-top: 10px;
    display: inline-block;
    width: 100%;
    font-weight: 500;
    font-size: 18pt;
    text-align: center;
}

.ServiceAreaRadioButton {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    color: black;
}

.Continue {
    border: none;
    color: white;
    background: none;
    margin: auto;
    font-size: 18pt;
    font-family: poppins;
    font-weight: 600;
}

.ServiceAreaHeaderBar {
    position: absolute;
    align-items: center;
    top: 24px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 10;
}

.ServiceAreaHeaderBarSmallViewPort {
    dipslay: flex;
    margin-left: 10px;
}

.ServiceAreaHeaderBarLogoContainer {
    display: flex;
    align-items: center;
    width: 220px;
    justify-content: center;
}

.ServiceAreaHeaderBarLogoContainer span {
    font-size: 20pt;
    font-weight: 600;
    padding-left: 10px;
}

.ServiceAreaSelectionButton {
    padding: 5px;
    width: 200px;
}

@media (min-width: 800px) {
    .ServiceAreaHeaderBody {
        display: flex;
        justify-content: space-evenly;
        height: 100%;
        width: 100%;
    }
}

@media (max-width: 800px) {
    .ServiceAreaHeaderBody {
        top: 100;
        position: relative;
        display: block;
        height: 100%;
        width: 100%;
    }
}

.ServiceAreaHeaderBarButtons {
    display: flex;
    justify-content: space-around;
    width: 450px;
}

.ServiceAreaHeaderBarButtonsLoggedIn {
    display: flex;
    justify-content: space-around;
    width: 575px;
}

.ServiceAreaRadio {
    display: flex;
    justify-content: center;
    background-color: #F5F5F5;
    border-radius: 20px;
    margin: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.InfoCarousel {
    display: flex;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #E0E0E0;
    height: 350px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.InfoCarouselNoBorders {
    display: flex;
    align-items: center;
    height: 350px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.InfoList, .InfoListNoBorders {
    display: flex;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    min-width: 1200px;
    justify-content: space-between;
}

.InfoList {
    border-radius: 24px;
    border: 1px solid #E0E0E0;
}

.InfoListTitle {
    font-size: 28pt;
    font-weight: 600;
    padding-bottom: 20px;
}

.InfoCarouselImageOne, .InfoCarouselImageTwo, .InfoCarouselImageThree, .InfoCarouselImageFour {
    display: inline-block;
    border-radius: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 300px;
    width: 300px;
    margin-left: 24px;
}

.InfoListImageOne, .InfoListImageTwo, .InfoListImageThree, .InfoListImageFour {
    display: inline-block;
    border-radius: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 300px;
    height: 300px;

}

.InfoCarouselImageOne, .InfoListImageOne {
    background-image: url('./images/drivers_info.png');
}

.InfoCarouselImageTwo, .InfoListImageTwo {
    background-image: url('./images/image13.jpeg');
}

.InfoCarouselImageThree, .InfoListImageThree {
    background-image: url('./images/image_3.png');
}

.InfoCarouselImageFour, .InfoListImageFour {
    background-image: url('./images/image_4.png');
}

.InfoCarouselPlaceHolder {
    display: inline-block;
    border-radius: 12px;
    background-image: url('./images/drivers_info.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 300px;
    width: 300px;
    margin-left: 24px;
    color: yellow;
    font-size: 20pt;
}

.SectionDiv {
    min-height: 200px;
}

.SectionDiv h2 {
    font-size: 22pt;
}

.SectionDivList {
    min-width: 400px;
}

.SectionDivList h2 {
    font-size: 18pt;
}

.SectionDiv p {
    font-size: 20pt;
}

.SectionDivList p {
    font-size: 16pt;
}

.InfoCarouselText, .SafeCarouselInfo {
    display: inline-block;
    width: 60%;
    height: 300px;
    overflow-y: auto;
    text-align: left;
    padding-left: 50px;
    padding-right: 10px;
    text-align: justify;
}

.InfoListText {
    display: inline-block;
    text-align: left;
    width: 900px;
    margin-top: 24px;
    margin-left: 50px;
    padding-right: 20px;
}

.InfoListImage {
    display: inline-block;
    width: 300px;
    margin-left: 24pt;
    margin-top: 24pt;
}

@media (max-width: 800px) {
    .InfoList, .InfoListNoBorders {
        display: inline-block;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        min-width: unset;
    }

    .InfoListText {
        display: inline-block;
        text-align: left;
        width: 100%;
        margin-top: unset;
        margin-left: unset;
        padding-right: unset;
    }

    .InfoListImage {
        display: inline-block;
        width: 100%;
        margin-left: unset;
    }

    .InfoListTitle {
        padding-left: 20px;
        padding-top: 20px;
    }

    .SectionDivList {
        min-width: unset;
        padding: 20px;
    }
}

@media (max-width: 800px) {
    .InfoCarousel {
        display: block;
        height: 1000px;
        width: 95%;
    }
    .InfoCarouselNoBorders {
        display: block;
        height: 1000px;
        width: 95%;
    }
    .InfoCarouselImageOne, .InfoCarouselImageTwo, .InfoCarouselImageThree, .InfoCarouselImageFour {
        display: block;
        width: 85%;
        margin-top: 24px;
        margin-bottom: 20px;
    }

    .InfoCarouselText {
        display: block;
        width: 75%;
        height: 800px;
        text-align: left;
    }
    .SectionDiv {
        min-height: 100px;
    }
}

.SafeCarousel {
    display: flex;
    align-items: center;
    height: 400px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    justify-content: spacing-between;
    text-align: left;
}

@media (max-width: 800px) {
    .SafeCarousel {
        display: block;
        height: 600px;
    }
}

.SafeCarouselBlock {}

@media (max-width: 800px) {
    .SafeCarouselBlock {
        display: inline-block;
        height: 300px;
        width: 80%
    }
}

.CarouselHighlight {
    font-size: 30pt;
    font-weight: 600;
    padding-bottom: 20px;
    text-align: left;
}

.SafeCarouselTitle {
    display: inline-block;
    width: 300px;
    margin-left: 24px;
    font-size: 18pt;
    text-align: center;
}

@media (max-width: 800px) {
    .SafeCarouselTitle {
        height: 100px;
        width: 100%;
        margin-left: 0px;
    }
}

.SafeCarouselInfo {
    display: inline-block;
    overflow-y: auto;
    width: 700px;
    text-align: justify;
}

@media (max-width: 800px) {
    .SafeCarouselInfo {
        width: 100%;
    }
}

.SelectedSafeCarousel {
    font-size: 16pt;
    color: #F85804;
    font-weight: 600;
    padding: 5px;
}

.UnselectedBigSafeCarousel {
    font-size: 14pt;
    color: #303030;
    font-weight: 400;
    padding: 5px;
}

.UnselectedSmallSafeCarousel {
    font-size: 12pt;
    color: #A9A9A9;
    padding: 5px;
;
}

@media (max-width: 800px) {
    .SafeCarousel {
        display: block;
        width: 100%;
    }
    .SafeCarouselInfo {
        display: block;
        width: 100%;
    }
}

.Faq {
    display: flex;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #E0E0E0;
    height: 650px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    text-align: left;
}

@media (max-width: 800px) {
    .Faq {
        display: inline-block;
        height: 850px;
    }
}

.FaqTitle {
   font-size: 30pt;
   display: inline-block;
   vertical-align: top;
   width: 200px;
   height: 100%;
   padding-left: 20px;
   padding-top: 30px;
   font-weight: 600;
}

@media (max-width: 800px) {
    .FaqTitle {
        height: 150px;
    }
}

.FaqSections {
   display: inline-block;
   overflow-y: auto;
   width: 70%;
   height: 600px;
   padding: 20px;
}

@media (max-width: 800px) {
    .FaqSections {
        width: 90%;
    }
}

.FaqSection {
    display: inline-block;
    border-bottom: 1px solid #E0E0E0;
    width: 100%;
}

.FaqSectionBlock {
    display: inline-block;
    width: 100%;
}

.FaqSectionTitle {
    display: flex;
    justify-content: space-between;
    height: 70px;
    align-items: center;
    font-size: 16pt;
    font-weight: 500;
    width: 90%;
}

.FaqSectionTitle span {
    display: flex;

}

.FaqSectionTitleButton {
    width: 50px;
}

@media (max-width: 800px) {
    .FaqSectionTitle {
        height: 75px;
    }
}

.OrangeText, .OrangeHeader {
    color: #F85804;
}

.OrangeHeader {
    font-size: 20pt;
    font-weight: 500;
}

.Footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* padding: 20px; */
    color: white;
    background-color: black;
    border-radius: 24px;
    height: 500px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    position: relative; 
}

.FooterOverlay {
    background-image: radial-gradient(circle, #F85804, black);
    opacity: 0.32;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 24px;
    z-index: 2;
}

.FooterTitle {
    position: relative;
    display: inline-block;
    font-size: 100pt;
    font-weight: 400;
    height: 30%;
    width: 100%;
    z-index: 3;
}

.FooterDocs {
    display: inline-block;
    height: 20%;
    width: 100%;
    text-align: center;
}

.FooterDocsBlock a, .FooterContactUsBlockLA a, .FooterContactUsBlockRA a, .FooterContactUsBlockRA a, 
.FooterDocsBlockLA a, .FooterDocsBlockRA a {
    text-decoration: none;
    color: white;
}

.FooterContactUsBar, .FooterDocsBar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25%;
    width: 100%;
    vertical-align: middle;
    z-index: 3;
}

.FooterContactUsBlock, .FooterDocsBlock {
    display: inline-block;
    font-size: 12pt;
    width: 300px;
}

/*.FooterDocsBlockTrademark {
    display: inline-block;
    font-size: 12pt;
    width: 150px;
}*/

.FooterContactUsBlockLA, .FooterDocsBlockLA, .FooterDocsBlockRA, .FooterContactUsBlockRA {
    display: inline-block;
    font-size: 12pt;
    width: 250px;               /* should be total 300 w left padding */
    line-height: 1.5;               /* Add space between items */
}

.FooterContactUsBlockRA {
    text-align: right;
    padding-right: 50px;           
}

.FooterContactUsBlockLA, .FooterDocsBlockLA {
    text-align: left;
    /* padding-left: 50px; */
}

.FooterContactUsBlock a {
    height: 75px;
    color: white;
    text-decoration: none;
}

.FooterDocsBlock {
    height: 25px;
    white-space: nowrap;
    text-align: center;
}

.FooterRow {
    display: flex; /* Keep this as flex for the first row */
    /* width: 100%; */
    z-index: 3;
}

.FooterColumn {
    flex: 1 1;
    padding: 50px;
    line-height: 1.5;

}

.Left {
    text-align: left;
}

.Center {
    text-align: center;
}

.Right {
    text-align: right;
}

@media (max-width: 800px) {
    .FooterTitle {
        padding-top: 50px;
        font-size: 50pt;
        font-weight: 500;
    }

    .FooterContactUsBlock, .FooterDocsBlock {
        width: 100%;
        padding-top: 5px;

    }

    .FooterContactUsBar, .FooterDocsBar  {
        display: inline-block;

    }

    .FooterContactUsBar {
         height: 250px;
    }

    .FooterDocsBar {
        height: 75px;
    }

    .FooterContactUsBlockLA, .FooterDocsBlockLA, .FooterDocsBlockRA, .FooterContactUsBlockRA {
        text-align: center;
        padding-right: 0px;
        padding-left: 0px;
        margin: 0 auto;
    }


    .FooterRow {
        display: block;
        width: auto;
    }

    .FooterColumn {
        width: 100%;
        padding: 5px;
        text-align: center;
    }

}

.TUXRydeText {
    font-size: 22pt;
    font-weight: 600;
    display: inline-block;
    width: 150px;
    vertical-align: middle;
    margin-left: 50px;
}

html {
    scroll-behavior: smooth;
  }

.logoContainer {
    display: flex;
    justify-content: space-between; /* Evenly spaces items */
    align-items: center;            /* Centers items vertically if needed */
    width: 192px;                   /* Total container width */
    margin: 0 auto;
    padding-top: 15px;
}

.logoContainer img {
    width: 36px;                    /* Logo width */
    height: 36px;                   /* Logo height */
    border-radius: 50%;             /* Makes each logo a circle */
}

