.TUXAppBarInnerCirleClear,  .TUXAppBarInnerCirleWhite, .TUXAppBarInnerCirleOrange {
    border: 1px solid rgba(224, 224,224, 0.2);
    border-radius: 30px;
    display: inline-block;
    width: 30px;
    height: 30px;
    align-items : center;
}

.TUXAppBarInnerCirleClear {
    border: 1px solid rgba(224, 224,224, 0.2);
}

.TUXAppBarInnerCirleWhite {
    border: 1px solid #E0E0E0;
}

.TUXAppBarInnerCirleOrange {
    border: 1px solid white;
}

.TUXAppBarTextContainer {
    display: flex;
    height: 30px;
    justify-content: space-between;
    width: 100%;
}

.TUXAppBarInnerText {
    display: flex;
    height: 30px;
    align-items : center;
    margin-right: 5px;
    margin-left: 5px;
}

.TUXAppBarCentered {
    margin: auto;
    text-align: center;
}

.TUXAppBarInnerCirleWhite svg, .TUXAppBarInnerCirleClear svg, .TUXAppBarInnerCirleOrange svg {
    vertical-align: middle;
    padding-top: 3px;
}

.white, .clear, .orange {
    display: flex;
    border-radius: 100px;
    height: 50px;
    align-items : center;
}

.white {
    color: black;
    background-color: white;
}

.clear {
    color: white;
    background-color: rgba(224, 224,224, 0.2);
}

.orange {
    color: white;
    background-color:  #F85804;
}

.TUXAppBarBordered {
    border: 2px solid #E0E0E0;
}

.TUXAppBarButtonWhiteSelected, .TUXAppBarButtonClearSelected, .TUXAppBarButtonOrangeSelected {
    border: none;
    background: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    font-family: poppins;
    cursor: pointer;
    width: 100%;
    font-weight: 600;
}

.TUXAppBarButtonDisabled, .TUXAppBarButtonWhite, .TUXAppBarButtonClear, .TUXAppBarButtonOrange {
    border: none;
    background: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    font-family: poppins;
    cursor: pointer;
    width: 100%;
    font-weight: 400;
}

.TUXAppBarButtonWhite {
    color: black;
}

.TUXAppBarButtonClear, .TUXAppBarButtonOrange, .TUXAppBarButtonClearSelected, .TUXAppBarButtonOrangeSelected {
    color: white;
}

.TUXAppBarButtonDisabled {
    color: grey;
}
