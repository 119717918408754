.RideLeg {
    padding: 10px;
}

.Popup {
    background-color: white;
    border-radius: 4px;
    border: thin solid lightgrey;
    padding: 20px;
    z-index: 10;
}

.Passenger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: #F5F5F5;
    border-radius: 30px;
    width: 250px;
    height: 50px;
    border: 1px solid #E0E0E0;
}

.Vehicle {
    display: flex;
    align-items: center; 
    justify-content: space-between;
    position: relative;
    background-color: #F5F5F5;
    border-radius: 30px;
    width: 250px;
    height: 50px;
    border: 1px solid #E0E0E0;
  }
  
.Passenger:hover, .Vehicle:hover {
    border: 1px solid #F85804;
}

.VehicleTypeOuter, .PassengerOuter {
    display: flex;
    align-items: end;
    position: relative;
    width: 250px;
    height: 57px;
}

.PassengerLabel, .VehicleTypeLabel {
    position: absolute;
    font-size: 12px;
    top: 0;
    left: 15px;
    text-align: left;
    z-index: 10;
    background-color: white;
}

.PassengerLabel {
    width: 70px;
}

.VehicleTypeLabel {
    width: 75px;
}

.PassengerOuter:hover {
    color: #F85804;
}

.Bags {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: #F5F5F5;
    border-radius: 30px;
    width: 250px;
    height: 50px;
    text-align: center;
    border: 1px solid #E0E0E0;
}

.Bags:hover {
    border: 1px solid #F85804;
}

.BagsNumber {
    display: inline-block;
    width: 20px;
}

.BagsBlock {
    width: 50px;
}

.BagsOuter {
    display: flex;
    align-items: end;
    position: relative;
    width: 250px;
    height: 57px;
}

.BagsLabel {
    position: absolute;
    font-size: 12px;
    top: 0;
    left: 15px;
    width: 30px;
    text-align: left;
    z-index: 10;
    background-color: white;
}

.BagsOuter:hover {
    color: #F85804;
}

.VehicleTypeOuter:hover {
    color: #F85804;

    .VehicleSpan {
        color: #F85804;
    }
}

.VehicleSpan {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
}

.InfoText {
    color: grey;
}

.InfoTitle {
    display: inline-block;
    width: 100px;
}

.AllDay {
    display: flex;
    width: 250px;
    align-items: center;
}

.AllDayInner {
    display: inline-block;
    width: 200px;
}

.EstimatedDuration {
    align-items : center;
    display: flex;
    width: 300px;
}

.Inner {
    vertical-align: center;
}

.PopupButton {
    min-width: 245px;
}

.VehicleTypeButton {
}

.VehicleTypeDiv {
    display: flex;
    height: 30px;
    justify-content: space-between;
    width: 200px;
}

.SpanLeft {
    color: black;
    display: flex;
    width: 35px;
    align-items: center;
}

.SpanLeftBlock {
    color: black;
    display: inline-block;
    width: 35px;
    text-align: right;
}

.SpanRight {
    color: black;
    display: flex;
    width: 35px;
    align-items: center;
    justify-content: right;
}

.VehicleTypeAutomatic {
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    justify-content: center;
    width: 80px;
}

.VehicleImage {
    display: flex;
    width: 80px;
    justify-content: center;
}

.RoundTrip {
    align-items: center;
    display: flex;
}

.RoundTripDateTime {
    display: inline-block;
    margin-left: 35px;
}

@media (max-width: 800px) {
    .Passenger, .Vehicle, .Bags, .BagsOuter, .PassengerOuter, .VehicleTypeOuter {
        width: 100%;
    }
}