@media (min-width: 800px) {
    .SignUp {
      display: flex;
      background-image: url('./images/sign_in_family.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 100vh;
      width: 100%;
      min-height: 1000px;
    }
}

@media (max-width: 800px) {
    .SignUp {
      height: 100vh;
      max-width: 360px;
      min-height: 1000px;
      margin: auto;
    }
}

.SignUpDialog {
  display: inline-block;
  width: 450px;
  margin-bottom: 30px;
}

@media (max-width: 800px) {
    .SignUpDialog {
      width: 375px;

    }
}

@media (min-width: 800px) {
    .SignUpContainer {
       border-radius: 24px;
       background-color: white;
       width: 425px;
       margin-top: 24px;
       margin-left: 24px;
       max-height: 1150px;
    }
}

@media (max-width: 800px) {
    .SignUpContainer {
       width: 340px;
       text-align: center;
    }
}

.SignUpLogo {
    display: flex;
    align-items: center;
    margin: auto;
    width: 375px;
    justify-content: start;
    padding-top: 30px;
}

@media (max-width: 800px) {
    .SignUpLogo {
        width: 300px;
    }
}

.SignUpMessage {
    font-size: 24px;
    font-weight: 500;
    width: 375px;
    padding-top: 20px;
    margin: auto;
}

@media (max-width: 800px) {
    .SignUpMessage {
        width: 300px;
    }
}

.OrangeText {
    color: #F85804;
}

.SignUpTitle {
    font-size: 24px;
    font-weight: 600;
}

.SignUpTitleSubText {
    color: #8F8F8F;
    font-size: 12px;
    font-weight: 400;
}

.SignUpControls {
    padding-top: 20px;
    width: 95%;
}

.CloseButton {
  text-align: right;
  width: 450px;
  padding: 0;
}

@media (max-width: 800px) {
    .CloseButton {
      width: 350px;
    }
}

.SignUpEmailButton {
    display: flex;
    border-radius: 100px;
    background-color: #F85804;
    height: 50px;
    width: 150px;
    align-items : center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.SignUpEmailButton button, .SignUpGoogleButton button {
    border: none;
    background: none;
    margin: auto;
    font-size: 16px;
    font-family: poppins;
    font-weight: 600;
}

.SignUpEmailButton button {
    color: white;
}

.SignUpGoogleButton button {
    color: white;
}

.SignUpButtonContainer {
    margin: auto;
    padding-top: 10px;
    width: 375px;
}

@media (max-width: 800px) {
    .SignUpButtonContainer {
       width: 300px;
    }
}

.SignUpTextField {
   margin: auto;
   width: 375px;
   padding-top: 10px;
}

@media (max-width: 800px) {
    .SignUpTextField {
       width: 300px;
    }
}

.SignUpOr {
    color: #B8B8B8;
    font-weight: 600;
    text-align: center;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 20px;
}

.SignUpLink {
    font-size: 14px;
    color: #F85804;
    font-weight: 600;
    text-decoration: none;
}

.SignUpGoogleText {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 200px;
}

.SignUpLinks {
    font-size: 14px;
    font-weight: 400;
    padding-top: 10px;
    width: 100%;
    margin: auto;
    text-align: center;
}
